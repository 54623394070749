import { Form, Input } from 'antd'
import Image from 'next/image'
import styles from './InputSecondary.module.css'
require('./InputSecondary.less')

const InputSecondary = ({ value, type = 'text', placeholder, label, name, style, className, error, errorMessage, disabled, readOnly, onChange }) => {
	return (
		<div className={`${styles.inputSecondary} input-secondary`}>
			<Form.Item
				name={readOnly ? undefined : name}
			>
				{
					type === 'text' ?
						<Input
							className={`${styles.input} ${(value === null || error) && styles.inputError} ${value && styles.inputFocused} ${className}`}
							placeholder={placeholder}
							disabled={disabled || readOnly}
							style={style}
							readOnly={readOnly}
							onChange={({ target:{ value } }) => onChange(value)}
						/>
						:
						type === 'password' ?
							<Input.Password
								className={`${styles.input} ${(value === null || error) && styles.inputError} ${value && styles.inputFocused} ${className}`}
								placeholder={placeholder}
								disabled={disabled || readOnly}
								style={style}
								readOnly={readOnly}
								onChange={({ target:{ value } }) => onChange(value)}
							/>
							: null
				}
			</Form.Item>
			<span className={`${styles.inputLabel} ${(value === null || error) && styles.inputLabelError} ${value && styles.inputLabelVisible} ${className} input-label`}>{label}</span>
			{
				value === null &&
				<div className={styles.errorMsgContainer}>
					<Image src={'/img/alert-circle.svg'} alt='Warning icon' width={16} height={16} />
					<p className={styles.errorMessageSecondary}>{errorMessage}</p>
				</div>
			}
		</div>)
}

export default InputSecondary
